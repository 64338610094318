import styles from './flat-subway.module.css';

import { Subway, SubwayLine } from 'core/entities/flats';

interface FlatSubwayProps {
  subway: Subway;
}

interface LineStyle {
  backgroundColor: string;
  border?: string;
  width?: number;
  height?: number;
}

const getLineStyle = (line: SubwayLine) => {
  const style: LineStyle = {
    backgroundColor: line.color
  };
  if (line.border) {
    style.border = `1px solid ${line.border}`;
  }
  return style;
};

export const FlatSubway = ({ subway }: FlatSubwayProps) => {
  const lineStyles = subway.lines.filter((line) => line.color).map(getLineStyle);

  if (lineStyles.length) {
    return (
      <span className={styles.root}>
        <span className={styles.lines}>
          {lineStyles.map((lineStyle, index) => (
            <span className={styles.circle} key={index} style={lineStyle} />
          ))}
        </span>
        <span className={styles.text}>{subway.name}</span>
      </span>
    );
  }

  return (
    <span className={styles.root}>
      <span className={styles.text}>м.&nbsp;{subway.name}</span>
    </span>
  );
};
