import styles from './reviews-stars.module.css';

import classNames from 'classnames';

import { ReviewStar, ReviewStarSize } from 'components/reviews/review-stars/reviews-star/review-star';

interface ReviewStarsProps {
  className?: string;
  rating: number;
  size: ReviewStarSize;
}

export const ReviewStars = ({ className, rating, size }: ReviewStarsProps) => {
  const items = [];

  for (let i = 0; i < 5; i = i + 1) {
    if (rating >= i + 0.9) {
      items.push(<ReviewStar className={styles.star} size={size} key={i} />);
    } else if (rating > i) {
      items.push(<ReviewStar className={styles.star} size={size} key={i} isHalf />);
    } else {
      items.push(<ReviewStar className={styles.star} size={size} key={i} isEmpty />);
    }
  }

  return <ul className={classNames(styles.root, className)}>{items}</ul>;
};
