import { useContext } from 'react';

import { FiltersContext } from 'contexts/filters/filters';

export const useApproximatePrice = () => {
  const { filters } = useContext(FiltersContext);

  const isDatesSelected = !!filters.dates.duration;
  const isGuestsSelected = filters.guestsSelected;

  return {
    showApproximatePrice: isGuestsSelected && isDatesSelected ? false : true,
    isDatesSelected,
    isGuestsSelected
  };
};
