import styles from './price.module.css';

import { useCallback } from 'react';

import { FILL_DATES, FILL_DATES_AND_GUESTS, FILL_GUESTS } from 'core/constants/price';
import { Currency, PriceRange } from 'core/entities';
import { getPricePerDay } from 'core/utils/flat/get-price-per-day';
import { WordForms } from 'core/utils/word-forms';

import { useApproximatePrice } from 'hooks/use-approximate-price';

import { Price as PriceValue } from 'components/common/price/price';

interface PriceProps {
  prices: PriceRange;
  currency: Currency;
  duration?: Optional<number>;
  simpleView?: boolean;
}

export const Price = ({ prices, currency, duration, simpleView }: PriceProps) => {
  const { showApproximatePrice, isDatesSelected, isGuestsSelected } = useApproximatePrice();

  let value = prices.min;
  if (duration) {
    value = prices.total ? prices.total : prices.min * duration;
  }

  const renderTotalPrice = useCallback(() => {
    if (!isDatesSelected && !isGuestsSelected) {
      return FILL_DATES_AND_GUESTS;
    }
    if (!isDatesSelected) {
      return FILL_DATES;
    }
    if (!isGuestsSelected) {
      return FILL_GUESTS;
    }
    if (duration && duration > 1) {
      return (
        <>
          <PriceValue currency={currency} value={value} /> за{' '}
          {new WordForms('сутки', 'суток', 'суток').getPlural(duration)}
        </>
      );
    }
    return null;
  }, [duration, value, isDatesSelected, isGuestsSelected, currency]);

  return (
    <div className={styles.root}>
      {!simpleView && showApproximatePrice && <span className={styles.from}>От </span>}
      <PriceValue className={styles.value} currency={currency} value={getPricePerDay(prices, duration || null)} />
      /сутки
      {!simpleView && <span className={styles.pricePerDays}>{renderTotalPrice()}</span>}
    </div>
  );
};
