import styles from './reviews.module.css';

import classNames from 'classnames';

import { WordForms } from 'core/utils/word-forms';

import { ReviewStars } from 'components/reviews/review-stars/review-stars';
import { ReviewStarSize } from 'components/reviews/review-stars/reviews-star/review-star';

interface ReviewsProps {
  className?: string;
  rating: number;
  numberOfReviews: number;
  size: ReviewStarSize;
}

export const Reviews = ({ className, rating, numberOfReviews, size }: ReviewsProps) => {
  return (
    <div className={classNames(styles.root, className)}>
      <ReviewStars className={classNames(styles.stars, styles[size])} rating={rating} size={size} />
      {new WordForms('отзыв', 'отзыва', 'отзывов').getPlural(numberOfReviews, '\xa0', true)}
    </div>
  );
};
