export const FLATLIST_TEXT_DAILY_RENT = 'Посуточная аренда';
export const FLATLIST_TEXT_NOTHING_FOUND = 'Ничего не найдено';
export const FLATLIST_TEXT_LOADING = 'Идет поиск';
export const FLATLIST_TEXT_LANDINGLIST_TITLE = 'Популярные подборки жилья';

export const FLATLIST_TEXT_CASHBACK_TITLE = 'Кэшбек до\xa030% бонусными рублями';
export const FLATLIST_TEXT_CASHBACK_BADGE = 'Условия акции';
export const FLATLIST_TEXT_CASHBACK_RETURN_ZERO = 'бонусов вернётся';

export const FLATLIST_TEXT_GUESTS_FILTER_CARD_TITLE =
  'Цены для разного состава гостей могут отличаться. Укажите состав гостей.';
export const FLATLIST_TEXT_GUESTS_FILTER_CARD_BADGE = 'Указать гостей';

export const FLATLIST_TEXT_CALENDAR_FILTER_CARD_TITLE =
  'Цены на\xa0разные даты могут отличаться. Укажите даты проживания.';
export const FLATLIST_TEXT_FOOTER_NEAREST_CITIES = 'Города поблизости';
export const FLATLIST_TEXT_CALENDAR_FILTER_CARD_BADGE = 'Указать даты';
export const LIMIT_OF_FLATS = 4;

export const FLAT_UNAVAILABLE_TITLE = 'Это жильё недоступно';
export const FLAT_UNAVAILABLE_GO_ANYWAY = 'Всё равно перейти';
export const FLAT_UNAVAILABLE_CHOOSE_ANOTHER = 'Выбрать другое жильё';
