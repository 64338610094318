import styles from './flat-card-cashback.module.css';

import { FLATLIST_TEXT_CASHBACK_RETURN_ZERO } from 'core/constants/flat-list';

import { AppParams } from 'controllers/app/app-params';
import { LinkParams } from 'controllers/link/link-params';

import CustomLink from 'components/common/link/link';
import { Text } from 'components/common/text/text';
import CashbackIcon from 'components/flat/flat-card/flat-card-cashback/cashback.svg';

interface FlatCardCashbackProps {
  cashBackAmount?: number;
  cashBackAmountText?: string;
  cashBackPercent?: number;
  cashBackPercentText?: string;
  withLink?: boolean;
  isApp?: boolean;
  withBonuses?: boolean;
}

export const FlatCardCashback = ({
  cashBackAmount,
  cashBackAmountText,
  cashBackPercent,
  cashBackPercentText,
  withLink,
  isApp,
  withBonuses
}: FlatCardCashbackProps) => {
  const link = new LinkParams()
    .addBaseLink('/info/help-guest-cashback/')
    .addAppParam(AppParams.openInBrowser, true)
    .setIsApp(!!isApp)
    .stringify();

  let cashbackText = null;

  if (cashBackAmount && cashBackAmountText) {
    cashbackText = (
      <>
        {withBonuses ? 0 : cashBackAmount}{' '}
        {withLink ? (
          <CustomLink href={link} newBlank>
            {withBonuses ? FLATLIST_TEXT_CASHBACK_RETURN_ZERO : cashBackAmountText}
          </CustomLink>
        ) : (
          cashBackAmountText
        )}
      </>
    );
  } else if (cashBackPercent && cashBackPercentText) {
    cashbackText = (
      <>
        {cashBackPercent}%{' '}
        {withLink ? (
          <CustomLink href={link} newBlank>
            {cashBackPercentText}
          </CustomLink>
        ) : (
          cashBackPercentText
        )}
      </>
    );
  } else {
    return null;
  }

  return (
    <div className={styles.root}>
      <CashbackIcon />
      <Text size={16}>{cashbackText}</Text>
    </div>
  );
};
