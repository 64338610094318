import { Advertisement } from 'core/entities/advertisement';
import { BookingFlat } from 'core/entities/booking';
import { CardFlat, CardFlatMini, Flat, UserFlat } from 'core/entities/flats';
import { BuildingType } from 'core/entities/housing';

export function getBuildingTypeText(
  flat: BookingFlat | Flat | CardFlat | CardFlatMini | Advertisement | UserFlat,
  verbose = false
) {
  switch (flat.buildingType) {
    case BuildingType.flat:
      return `${flat.rooms}-${verbose ? 'комнатная квартира' : 'комн.'}`;
    case BuildingType.room:
      return 'Комната';
    case BuildingType.cottage:
      return 'Дом';
    default:
      return '';
  }
}
