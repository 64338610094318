import { getYear } from 'date-fns';

import { WordForms } from 'core/utils/word-forms';

import { Text } from 'components/common/text/text';

interface FlatCreatedProps {
  created: string;
}

export const FlatCreated = ({ created }: FlatCreatedProps) => {
  const createdDate = new Date(created);
  const currentDate = new Date();

  if (getYear(currentDate) > getYear(createdDate)) {
    const years = getYear(currentDate) - getYear(createdDate);
    const wordForm = new WordForms('год', 'года', 'лет');

    return <Text size={16}>{wordForm.getPlural(years, '\xa0', true)} на Квартирке</Text>;
  }

  return <Text size={16}>Новинка</Text>;
};
