import { Reviews as ReviewsType } from 'core/entities/reviews';

import { FlatCreated } from 'components/flat/flat-card/flat-card-info/achievement/flat-created/flat-created';
import { Reviews } from 'components/reviews/reviews';

interface AchievementProps {
  created: string;
  reviews: ReviewsType;
}

export const Achievement = (props: AchievementProps) => {
  if (props.reviews.count > 0) {
    return <Reviews rating={props.reviews.average} numberOfReviews={props.reviews.count} size='sm' />;
  }

  return <FlatCreated created={props.created} />;
};
