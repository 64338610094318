import styles from './address.module.css';

import { Subway } from 'core/entities/flats';

import { FlatSubway } from 'components/flat/flat-subway/flat-subway';

interface AddressProps {
  address: string;
  stations?: Array<Subway>;
}

export const Address = ({ address, stations }: AddressProps) => {
  const subway = stations ? stations[0] : null;
  if (subway && subway.name) {
    return <FlatSubway subway={subway} />;
  }
  return <span className={styles.root}>{address}</span>;
};
