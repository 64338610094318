import styles from './flat-card-info.module.css';

import classNames from 'classnames';

import { Currency } from 'core/entities';
import { FiltersFeaturesNames } from 'core/entities/filters';
import { CardFlat, UserFlat } from 'core/entities/flats';
import { getBuildingTypeText } from 'core/utils/flat/get-building-type-text';

import LightingIcon from 'components/common/icons/lighting.svg';
import PhotocheckIcon from 'components/common/icons/photocheck.svg';
import { FlatCardCashback } from 'components/flat/flat-card/flat-card-cashback/flat-card-cashback';
import { Achievement } from 'components/flat/flat-card/flat-card-info/achievement/achievement';
import { Address } from 'components/flat/flat-card/flat-card-info/address/address';
import { Price } from 'components/flat/flat-card/flat-card-info/price/price';

interface FlatCardInfoProps {
  className?: string;
  flat: CardFlat | UserFlat;
  currency: Currency;
  duration?: Optional<number>;
  simpleView?: boolean;
}

export const FlatCardInfo = ({ className, flat, currency, duration, simpleView }: FlatCardInfoProps) => {
  let isPhotocheck;
  if ('photos' in flat) {
    isPhotocheck = flat.photos.some((photo: { verified: boolean }) => photo.verified);
  } else {
    isPhotocheck = flat.photo?.verified;
  }

  let stations;
  if ('stations' in flat) {
    stations = flat.stations;
  }

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.flat}>
        <div className={styles.flatInfo}>
          {flat.owner.profile && flat.instantBooking && (
            <span className={styles.flatIcon} title={FiltersFeaturesNames.instantBooking}>
              <LightingIcon />
            </span>
          )}
          {isPhotocheck && (
            <span className={styles.flatIcon} title={FiltersFeaturesNames.photocheck}>
              <PhotocheckIcon />
            </span>
          )}
          <span className={styles.buildingType}>{getBuildingTypeText(flat)}</span>
        </div>
        {flat.address && <Address address={flat.address} stations={stations} />}
      </div>

      {flat.prices && <Price prices={flat.prices} duration={duration} currency={currency} simpleView={simpleView} />}

      <Achievement created={flat.created} reviews={flat.reviews} />
      <FlatCardCashback
        cashBackAmount={flat.cashBackAmount}
        cashBackAmountText={flat.cashBackAmountText}
        cashBackPercent={flat.cashBackPercent}
        cashBackPercentText={flat.cashBackPercentText}
      />
    </div>
  );
};
