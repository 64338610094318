import styles from './review-star.module.css';

import classNames from 'classnames';

export type ReviewStarSize = 'sm' | 'md' | 'lg';

interface ReviewStarProps {
  className?: string;
  size: ReviewStarSize;
  isHalf?: boolean;
  isEmpty?: boolean;
}

export const ReviewStar = ({ className, size, isHalf, isEmpty }: ReviewStarProps) => (
  <li
    className={classNames(styles.root, className, styles[size], { [styles.half]: isHalf, [styles.empty]: isEmpty })}
  />
);
